const theme = {}

theme.delegate = {
  on: function (event, callback, options) {
    if (!this.namespaces)
      // save the namespaces on the DOM element itself
      this.namespaces = {}

    this.namespaces[event] = callback
    options = options || false

    this.addEventListener(event.split('.')[0], callback, options)
    return this
  },
  off: function (event) {
    if (!this.namespaces) {
      return
    }
    this.removeEventListener(event.split('.')[0], this.namespaces[event])
    delete this.namespaces[event]
    return this
  },
}

// Extend the DOM with these above custom methods
window.on = Element.prototype.on = theme.delegate.on
window.off = Element.prototype.off = theme.delegate.off

function DOMready(callback) {
  document.readyState !== 'loading'
    ? callback()
    : document.addEventListener('DOMContentLoaded', callback)
}

DOMready(function () {
  const lazyVideos = [...document.querySelectorAll('video.lazy')]
  const featuredProducts = document.querySelectorAll('.featured-products')

  if (featuredProducts.length > 0) {
    theme.Modals = (function () {
      function Modal(id, name, options) {
        const defaults = {
          close: '.js-modal-close',
          open: '.js-modal-open-' + name,
          openClass: 'modal--is-active',
          closingClass: 'modal--is-closing',
          bodyOpenClass: ['modal-open'],
          bodyOpenSolidClass: 'modal-open--solid',
          bodyClosingClass: 'modal-closing',
          closeOffContentClick: true,
        }

        this.id = id
        this.modal = document.getElementById(id)

        if (!this.modal) {
          return false
        }

        this.modalContent = this.modal.querySelector('.video-modal__inner')
        this.video = this.modal.querySelector('video')
        if (this.video != null) {
          this.videoBtnPlay = this.modal.querySelector('.video-modal__btn-play')
        }

        this.config = Object.assign(defaults, options)
        this.modalIsOpen = false
        this.focusOnOpen = this.config.focusIdOnOpen
          ? document.getElementById(this.config.focusIdOnOpen)
          : this.modal
        this.isSolid = this.config.solid

        this.init()
      }

      Modal.prototype.init = function () {
        document.querySelectorAll(this.config.open).forEach((btn) => {
          btn.setAttribute('aria-expanded', 'false')
          btn.addEventListener('click', this.open.bind(this))
        })

        this.modal.querySelectorAll(this.config.close).forEach((btn) => {
          btn.addEventListener('click', this.close.bind(this))
        })

        // Close modal if a drawer is opened
        document.addEventListener(
          'drawerOpen',
          function () {
            this.close()
          }.bind(this)
        )

        if (this.video != null) {
          this.videoBtnPlay = this.modal.querySelector('.video-modal__btn-play')

          this.video.parentElement.addEventListener('click', () => {
            if (this.video.paused) {
              this.video.play()
              this.videoBtnPlay.classList.add('video-modal__btn-play--hidden')
            } else {
              this.video.pause()
              this.videoBtnPlay.classList.remove(
                'video-modal__btn-play--hidden'
              )
            }
          })
        }
      }

      Modal.prototype.open = function (evt) {
        // Keep track if modal was opened from a click, or called by another function
        var externalCall = false

        // don't open an opened modal
        if (this.modalIsOpen) {
          return
        }

        // Prevent following href if link is clicked
        if (evt) {
          evt.preventDefault()
        } else {
          externalCall = true
        }

        // Without this, the modal opens, the click event bubbles up to $nodes.page
        // which closes the modal.
        if (evt && evt.stopPropagation) {
          evt.stopPropagation()
          // save the source of the click, we'll focus to this on close
          this.activeSource = evt.currentTarget.setAttribute(
            'aria-expanded',
            'true'
          )
        }

        if (this.modalIsOpen && !externalCall) {
          this.close()
        }

        this.modal.classList.add(this.config.openClass)

        document.documentElement.classList.add(...this.config.bodyOpenClass)

        if (this.isSolid) {
          document.documentElement.classList.add(this.config.bodyOpenSolidClass)
        }

        this.modalIsOpen = true

        document.dispatchEvent(new CustomEvent('modalOpen'))
        document.dispatchEvent(new CustomEvent('modalOpen.' + this.id))

        if (this.video != null) {
          const isPlaying =
            this.video.currentTime > 0 &&
            !this.video.paused &&
            !this.video.ended &&
            this.video.readyState > this.video.HAVE_CURRENT_DATA
          if (!isPlaying) {
            const playVideo = this.video.play()
            if (playVideo !== undefined) {
              playVideo.then((_) => {}).catch((error) => {})
            }
          }

          this.videoBtnPlay.classList.add('video-modal__btn-play--hidden')
        }

        this.bindEvents()
      }

      Modal.prototype.close = function (evt) {
        // don't close a closed modal
        if (!this.modalIsOpen) {
          return
        }

        // Do not close modal if click happens inside modal content
        if (evt) {
          if (evt.target.closest('.js-modal-close')) {
            // Do not close if using the modal close button
          } else if (evt.target.closest('.video-modal__inner')) {
            return
          }
        }

        // deselect any focused form elements
        document.activeElement.blur()

        this.modal.classList.remove(this.config.openClass)
        this.modal.classList.add(this.config.closingClass)

        document.documentElement.classList.remove(...this.config.bodyOpenClass)
        document.documentElement.classList.add(this.config.bodyClosingClass)

        window.setTimeout(
          function () {
            document.documentElement.classList.remove(
              this.config.bodyClosingClass
            )
            this.modal.classList.remove(this.config.closingClass)
            if (
              this.activeSource &&
              this.activeSource.getAttribute('aria-expanded')
            ) {
              this.activeSource.setAttribute('aria-expanded', 'false').focus()
            }
          }.bind(this),
          500
        ) // modal close css transition

        if (this.isSolid) {
          document.documentElement.classList.remove(
            this.config.bodyOpenSolidClass
          )
        }

        this.modalIsOpen = false

        document.dispatchEvent(new CustomEvent('modalClose.' + this.id))

        if (this.video != null) {
          this.video.pause()
        }

        this.unbindEvents()
      }

      Modal.prototype.bindEvents = function () {
        window.on(
          'keyup.modal',
          function (evt) {
            if (evt.keyCode === 27) {
              this.close()
            }
          }.bind(this)
        )

        if (this.config.closeOffContentClick) {
          // Clicking outside of the modal content also closes it
          this.modal.on('click.modal', this.close.bind(this))
        }
      }

      Modal.prototype.unbindEvents = function () {
        document.documentElement.off('.video-modal')

        if (this.config.closeOffContentClick) {
          this.modal.off('.video-modal')
        }
      }

      return Modal
    })()

    const featuredModals = document.querySelectorAll(
      '.product-card__button-popup'
    )

    featuredModals.forEach((slide) => {
      // eslint-disable-next-line no-undef, no-unused-vars
      const modal = new theme.Modals(
        slide.hash.substr(1),
        slide.hash.substr(1),
        {
          closeOffContentClick: true,
          bodyOpenClass: ['modal-open', 'popup-modal-open'],
          solid: true,
        }
      )
    })
  }

  if ('IntersectionObserver' in window) {
    // eslint-disable-next-line no-undef
    const lazyVideoObserver = new IntersectionObserver(function (entries) {
      entries.forEach(function (video) {
        if (video.isIntersecting) {
          for (const source in video.target.children) {
            const videoSource = video.target.children[source]
            if (
              typeof videoSource.tagName === 'string' &&
              videoSource.tagName === 'SOURCE'
            ) {
              videoSource.src = videoSource.dataset.src
            }
          }

          video.target.load()
          video.target.classList.remove('lazy')
          lazyVideoObserver.unobserve(video.target)
        }
      })
    })

    lazyVideos.forEach(function (lazyVideo) {
      lazyVideoObserver.observe(lazyVideo)
    })
  }
})
